import { makeStyles } from "../../../../common/Theme/Theme";
const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    regular_text_field: {
      paddingBottom: spacing(2),
    },
    legal_entity: {
      display: "flex",
      flexDirection: "column",
      rowGap: spacing(0.5),
      border: `1px solid ${palette.grayVariation.grayForBorders}`,
      borderRadius: spacing(0.5),
      padding: spacing(2),
    },
    checked_radio_button: {
      color: `${palette.themeColor.secondary} !important`,
    },
    group_label: {
      marginBottom: spacing(2),
    },
    form_control: {
      marginBottom: `${spacing(4)}px !important`,
      marginTop: `${spacing(4)}px !important`,
    },
    data_box: {
      width: "-webkit-fill-available",
    },
    data_box_container: {
      flexGrow: 1,
    },
    form_group: {
      gap: spacing(2),
    },
  };
});

export { useStyles };
