import React, { useEffect, useState } from "react";
import { array, number, object, oneOfType, string } from "prop-types";
import labels from "../../../../config/labels";
import { useStyles } from "./LegalNoticeFields.style";
import useSubmitableContentForm from "../../useSubmitableContentForm";
import Typography from "../../../../common/Typography/Typography";
import RadioButtonGroup from "../../../../common/RadioButtonGroup/RadioButtonGroup";
import ScheduledDate from "./ScheduledDate/ScheduledDate";
import {
  LEGAL_ENTITY_ID,
  SCHEDULED_DATES,
  LEGAL_NOTICE_NAME,
  MANDATORY_FIELDS_FOR_LEGAL_NOTICE,
} from "../../diyConstants";
import { fetchLegalNoticeEntities } from "../../../../services/users";
import TextField from "../../../../common/TextField/TextField";

const LegalNoticeFields = ({ formErrors, content, userId }) => {
  const classes = useStyles();
  const [legalEntities, setLegalNotices] = useState([]);
  const [dates, setDates] = useState(
    content?.scheduled_dates?.length > 0
      ? content.scheduled_dates.map((d) => new Date(d))
      : [new Date()]
  );
  const [selectedLegalEntityId, setSelectedLegalEntityId] = useState(
    content?.legal_notice_entity_id ? content.legal_notice_entity_id : null
  );
  const [legalNoticeName, setLegalNoticeName] = useState(
    content?.name ? content.name : ""
  );
  const { setProperty } = useSubmitableContentForm();

  useEffect(() => {
    const fetchLegalNoticesForUser = async () => {
      const response = await fetchLegalNoticeEntities(userId);
      if (response?.status === 200) {
        setLegalNotices(response?.data);
        if (!content?.legal_notice_entity_id) {
          setSelectedLegalEntityId(response?.data[0]?.id);
        }
      }
    };

    if (legalEntities.length === 0) {
      fetchLegalNoticesForUser();
    }
  }, [userId]);

  useEffect(() => {
    setProperty(LEGAL_ENTITY_ID, selectedLegalEntityId);
    setProperty(SCHEDULED_DATES, dates);
    setProperty(LEGAL_NOTICE_NAME, legalNoticeName);
  }, [selectedLegalEntityId, dates, legalNoticeName]);

  const shouldMarkError = (prop) => {
    let validation;

    validation = MANDATORY_FIELDS_FOR_LEGAL_NOTICE.find(
      (mandatory) => mandatory.key === prop
    );

    if (formErrors?.length > 0 && formErrors.includes(validation.error)) {
      return true;
    }
  };

  const titleize = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const printItem = (item) => {
    const { value, key } = item;
    return value && key !== "id";
  };

  const radioButtonItems = () => {
    return legalEntities?.map((item) => (
      <div className={classes.legal_entity}>
        {Object.entries(item).map(
          ([key, value]) =>
            printItem({ key, value }) && (
              <Typography level="paragraph_2">
                <strong>{titleize(key)}</strong>: {value}
              </Typography>
            )
        )}
      </div>
    ));
  };

  return (
    <>
      <TextField
        label={labels.LEGAL_NOTICE_NAME}
        onChange={(e) => setLegalNoticeName(e.target.value)}
        defaultValue={legalNoticeName}
        labelLevel="h4_sub_header_4"
        required
        error={shouldMarkError(LEGAL_NOTICE_NAME)}
      />
      {legalEntities?.length > 0 && selectedLegalEntityId && (
        <RadioButtonGroup
          groupLabel={
            <Typography level="h4_sub_header_4" color="gray" bold>
              {labels.CHOOSE_A_LEGAL_NOTICE_ENTITY}
            </Typography>
          }
          items={radioButtonItems()}
          radioGroupProps={{
            row: true,
            value: selectedLegalEntityId,
            onChange: (e) =>
              setSelectedLegalEntityId(
                Number(e.target.value) || legalEntities[0]?.id
              ),
            classes: { root: classes.form_group },
          }}
          classes={{
            radio_button: { checked: classes.checked_radio_button },
            group_label: { root: classes.group_label },
            form_control: { root: classes.form_control },
            form_control_label: {
              label: classes.data_box,
              root: classes.data_box_container,
            },
          }}
        />
      )}

      <Typography level="h4_sub_header_4" color="gray" bold>
        {labels.SCHEDULE_PUBLICATION_DATE}
      </Typography>
      <ScheduledDate dates={dates} setDates={setDates} />
    </>
  );
};

LegalNoticeFields.propTypes = {
  content: object,
  userId: oneOfType([string, number]),
  formErrors: array,
};

export default LegalNoticeFields;
