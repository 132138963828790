import restClient from "../../utils/restClient";
import withConfig from "../../utils/withConfig";

export const fetchThemeTemplates = async () => {
  try {
    const response = await restClient.get(withConfig("FETCH_THEME_TEMPLATES"));

    return response;
  } catch (error) {
    throw error;
  }
};
