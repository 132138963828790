import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import restClient from "../../../utils/restClient";
import withConfig from "../../../utils/withConfig";
import { fetchTheme } from "../../../services/townService";
import { fetchThemeTemplates } from "../../../services/CMS/themeService";

const ERROR_VARIANT = "error center_in_screen";
const messages = {
  update: {
    success: "Link successfully updated",
    error: "Error 500 while updating the link. Please try again later.",
  },
  remove: {
    success: "Link successfully removed",
    error: "Error 500 while removing the link. Please try again later.",
  },
  create: {
    success: "Link successfully created",
    error: "Error 500 while creating the link. Please try again later.",
  },
  save_town_theme_preferences: {
    success: "Successfully updated your town's theme",
    error:
      "Error 500 while updating your town's theme. Please try again later.",
  },
};

const TownThemeContext = React.createContext({});

const TownThemeContextProvider = ({ children }) => {
  const [town, setTown] = useState();
  const [townId, setTownId] = useState();
  const [themeColors, setThemeColors] = useState();
  const [themeTexts, setThemeTexts] = useState();
  const [firstBlockButtons, setFirstBlockButtons] = useState([]);
  const [secondBlockButtons, setSecondBlockButtons] = useState([]);
  const [themeManagerInfo, setThemeManagerInfo] = useState([]);
  const [footerOptions, setFooterOptions] = useState();
  const [gamesSection, setGamesSection] = useState();
  const [themeTemplates, setThemeTemplates] = useState();
  const [townTemplate, setTownTemplate] = useState();
  const [townThemePhotos, setTownThemePhotos] = useState([]);

  useEffect(() => {
    const loadThemeTemplates = async () => {
      const response = await fetchThemeTemplates();

      if (response?.status === 200) {
        setThemeTemplates(response?.data);
      }
    };
    const loadCurrentTownTemplate = async () => {
      const response = await fetchTheme(townId);

      if (response?.status === 200) {
        setTownTemplate(response?.data?.theme_template);
      }
    };

    loadThemeTemplates();
    if (townId) {
      loadCurrentTownTemplate();
    }

    return () => {
      setThemeTemplates();
      setTownTemplate();
    };
  }, [townId]);

  const fetchTownTheme = async () => {
    const response = await restClient.get(
      withConfig("FETCH_TOWN_THEME").replace(":id", townId)
    );
    if (response?.status === 200) {
      setTown(response?.data?.town);
      setFirstBlockButtons(response?.data?.first_block_buttons);
      setSecondBlockButtons(response?.data?.second_block_buttons);
      setThemeColors(response?.data?.theme_colors);
      setThemeTexts(response?.data?.theme_texts);
      setFooterOptions(response?.data?.footer_options);
      setGamesSection(response?.data?.games_section_enabled);
      setTownThemePhotos(response?.data?.photos);
    }
  };

  const submit = async (
    primaryColor,
    secondaryColor,
    signupBackgroundColor,
    mastheadBackgroundColor,
    mastheadContrastColor,
    customMessages,
    isGamesSectionEnabled
  ) => {
    try {
      const response = await restClient.post(
        withConfig("SAVE_TOWN_THEME").replace(":id", town.id),
        {
          primary_color: primaryColor,
          secondary_color: secondaryColor,
          sign_up_background_color: signupBackgroundColor,
          games_section: isGamesSectionEnabled,
          masthead_background_color: mastheadBackgroundColor,
          masthead_contrast_color: mastheadContrastColor,
          ...customMessages,
        }
      );

      return manageResponseBehaviour(response, "save_town_theme_preferences");
    } catch (e) {
      console.error({ e });
      setThemeManagerInfo({
        open: true,
        message: messages["save_town_theme_preferences"]["error"],
        variant: ERROR_VARIANT,
      });
    }
  };

  const saveActionButton = async (
    position,
    blockNumber,
    action,
    defaultAction,
    label,
    buttonColor,
    labelColor,
    borderColor
  ) => {
    const response = await restClient.post(
      withConfig("SAVE_TOWN_BUTTON").replace(":id", town.id),
      {
        position,
        blockNumber,
        buttonAction: action,
        defaultAction,
        label,
        buttonColor,
        labelColor,
        borderColor,
      }
    );

    if (response?.status === 200) {
      setThemeManagerInfo({
        open: true,
        message: "Successfully updated",
        variant: "success center_in_screen",
      });
      await fetchTownTheme();
    } else {
      setThemeManagerInfo({
        open: true,
        message: "There was an error",
        variant: "error center_in_screen",
      });
    }
  };

  const restoreButtonsToDefault = async () => {
    const response = await restClient.post(
      withConfig("RESTORE_BUTTONS_TO_DEFAULT").replace(":id", town.id)
    );
    if (response?.status === 200) {
      setThemeManagerInfo({
        open: true,
        message: "Successfully restored",
        variant: "success center_in_screen",
      });
      await fetchTownTheme();
    } else {
      setThemeManagerInfo({
        open: true,
        message: "There was an error",
        variant: "error center_in_screen",
      });
    }
    window.location.reload();
  };

  const restoreTheme = async () => {
    const response = await restClient.post(
      withConfig("RESTORE_THEME_TO_DEFAULT").replace(":id", town.id)
    );
    if (response?.status === 200) {
      setThemeManagerInfo({
        open: true,
        message: "Successfully restored",
        variant: "success center_in_screen",
      });
      await fetchTownTheme();
    } else {
      setThemeManagerInfo({
        open: true,
        message: "There was an error",
        variant: "error center_in_screen",
      });
    }
    window.location.reload();
  };

  const manageResponseBehaviour = (response, actionType) => {
    try {
      if (response?.status === 200) {
        setThemeManagerInfo({
          open: true,
          message: messages[actionType]["success"],
          variant: "success center_in_screen",
        });
        return response;
      } else {
        setThemeManagerInfo({
          open: true,
          message: messages[actionType]["error"],
          variant: ERROR_VARIANT,
        });
      }
    } catch (e) {
      console.error({ e });
      setThemeManagerInfo({
        open: true,
        message: messages[actionType]["error"],
        variant: ERROR_VARIANT,
      });
    }
  };

  useEffect(() => {
    if (townId) {
      fetchTownTheme();
    }
  }, [townId]);

  const updateTemplate = async (template, currentTemplateId) => {
    try {
      const response = await restClient.post(
        withConfig("UPDATE_THEME_TEMPLATE").replace(":id", currentTemplateId),
        {
          template,
        }
      );
      return manageResponseBehaviour(response, "save_town_theme_preferences");
    } catch (e) {
      console.error({ e });
      setThemeManagerInfo({
        open: true,
        message: messages["save_town_theme_preferences"]["error"],
        variant: ERROR_VARIANT,
      });
    }
  };

  return (
    <TownThemeContext.Provider
      value={{
        setTownId,
        submit,
        firstBlockButtons,
        secondBlockButtons,
        town,
        themeColors,
        themeTexts,
        themeManagerInfo,
        fetchTownTheme,
        setFirstBlockButtons,
        setThemeManagerInfo,
        saveActionButton,
        restoreButtonsToDefault,
        restoreTheme,
        footerOptions,
        setFooterOptions,
        gamesSection,
        setGamesSection,
        themeTemplates,
        updateTemplate,
        townTemplate,
        townThemePhotos,
      }}
    >
      {children}
    </TownThemeContext.Provider>
  );
};

TownThemeContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useTownThemeManagement = () => {
  const TownThemeContextValues = useContext(TownThemeContext);

  if (!TownThemeContextValues) {
    throw new Error(
      "useContext must be used within a descendant of the TownThemeContextProvider"
    );
  }

  return TownThemeContextValues;
};

const withTownThemeContextProvider = (Component) => {
  const ComponentWithProvider = (props) => (
    <TownThemeContextProvider>
      <Component {...props} />
    </TownThemeContextProvider>
  );

  return ComponentWithProvider;
};

export { useTownThemeManagement, withTownThemeContextProvider };
